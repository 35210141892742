import { getUserProfile } from '../../../../api/Identity';
import AuthenticationSingleton from '../../../../authentication/Authentication';
import { ROUTE, ROUTE_ENTITY, SLE_STATUS } from '../../../../constants';
import { getMvpd } from '../../../../helpers';
import PlayerStoreSingleton from '../../../../store/PlayerStore/PlayerStore';
import { StreamAuthorizationType } from '../request';
import { missingDataError, noCreditsError, parentalControlsError, unauthenticatedError, unauthorizedError, } from '../error';
import { getLemonade, setLemonade, setPreCheckLemonade, } from '../../../../store/PlayerStore/actions/lemonade';
import { isEmpty } from 'lodash';
import RouterUtil from '../../../../util/RouterUtil';
import { isVod } from '../../../../components/player/helpers/metadata';
/**
 * Formats the rating so it can be used by Adobe
 * Refer to https://experienceleague.adobe.com/en/docs/pass/authentication/auth-features/user-metadat/user-metadata-feature for rating examples
 */
const formatRating = (rating) => {
    var _a, _b;
    if (!rating)
        return 'TV-Y';
    // Clear advisories data and cast to uppercase
    rating = (_b = (_a = rating === null || rating === void 0 ? void 0 : rating.match(/^\S*/)) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.toUpperCase();
    // If rating comes as i.e.: TV14, instead of TV-14, add dash
    rating = (rating === null || rating === void 0 ? void 0 : rating.includes('TV')) && !(rating === null || rating === void 0 ? void 0 : rating.includes('-')) ? rating === null || rating === void 0 ? void 0 : rating.replace('TV', 'TV-') : rating;
    return rating;
};
const authorize = async (request, stream, program) => {
    var _a, _b, _c, _d;
    const channelId = ((_b = (_a = stream === null || stream === void 0 ? void 0 : stream.channelId) === null || _a === void 0 ? void 0 : _a.toLowerCase) === null || _b === void 0 ? void 0 : _b.call(_a)) || '';
    const contentType = (stream === null || stream === void 0 ? void 0 : stream.contentType) || (stream === null || stream === void 0 ? void 0 : stream.pid) ? 'sle' : 'linear';
    const asset = {
        title: (program === null || program === void 0 ? void 0 : program.programTitle) || (program === null || program === void 0 ? void 0 : program.title),
        mpxGuid: (program === null || program === void 0 ? void 0 : program.mpxGuid) || (program === null || program === void 0 ? void 0 : program.nbcuId) || (program === null || program === void 0 ? void 0 : program.tmsId) || (program === null || program === void 0 ? void 0 : program.brandV4ID) || (stream === null || stream === void 0 ? void 0 : stream.pid),
        rating: formatRating((program === null || program === void 0 ? void 0 : program.rating) || (program === null || program === void 0 ? void 0 : program.ratingWithAdvisories) || ((_c = program === null || program === void 0 ? void 0 : program.ratingAdvisories) === null || _c === void 0 ? void 0 : _c[0])),
        resourceId: (program === null || program === void 0 ? void 0 : program.resourceId) || (stream === null || stream === void 0 ? void 0 : stream.resourceId),
        league: (program === null || program === void 0 ? void 0 : program.league) || (stream === null || stream === void 0 ? void 0 : stream.league),
    };
    request.token = await AuthenticationSingleton.authorize(asset);
    if (!request.token) {
        request.error = unauthenticatedError({});
        throw request;
    }
    if (((_d = request.token) === null || _d === void 0 ? void 0 : _d.status) === 403) {
        request.error = unauthorizedError(request.token);
        throw request;
    }
    if (!isVod(stream.programmingType)) {
        const jwt = await AuthenticationSingleton.verifyMediaToken(request.token, channelId, contentType);
        if (!(jwt === null || jwt === void 0 ? void 0 : jwt.valid)) {
            request.error = unauthenticatedError({});
            throw request;
        }
    }
    return request;
};
export const linearSleFerAuthorize = async (request, stream, program, lemonade, isPreCheck) => {
    var _a, _b;
    if (request.authType !== StreamAuthorizationType.UNLOCKED)
        await authorize(request, stream, program);
    if (isEmpty(lemonade)) {
        try {
            const lemonade = await getLemonade(stream)();
            if (((_a = lemonade === null || lemonade === void 0 ? void 0 : lemonade.payload) === null || _a === void 0 ? void 0 : _a.status) === SLE_STATUS.EXPIRED || ((_b = lemonade === null || lemonade === void 0 ? void 0 : lemonade.payload) === null || _b === void 0 ? void 0 : _b.errors)) {
                throw request;
            }
            if (isPreCheck)
                await PlayerStoreSingleton.dispatch(setPreCheckLemonade(lemonade === null || lemonade === void 0 ? void 0 : lemonade.payload));
            else
                await PlayerStoreSingleton.dispatch(setLemonade(lemonade === null || lemonade === void 0 ? void 0 : lemonade.payload));
        }
        catch (_c) {
            request.error = missingDataError('Expired asset');
            throw request;
        }
    }
    return request;
};
export const vodAuthorize = (bypass) => async (request, stream, program) => {
    var _a, _b, _c, _d;
    if (!(program === null || program === void 0 ? void 0 : program.locked)) {
        request.authType = StreamAuthorizationType.UNLOCKED;
        return request;
    }
    try {
        await authorize(request, stream, program);
    }
    catch (_e) {
        const userProfile = getUserProfile();
        const mvpd = getMvpd();
        if (((_a = request === null || request === void 0 ? void 0 : request.token) === null || _a === void 0 ? void 0 : _a.message) === 'noAuthz')
            throw request;
        if (!!mvpd && ((_d = (_c = (_b = request === null || request === void 0 ? void 0 : request.token) === null || _b === void 0 ? void 0 : _b.details) === null || _c === void 0 ? void 0 : _c.toLowerCase) === null || _d === void 0 ? void 0 : _d.call(_c).search('parent')) > -1) {
            request.error = parentalControlsError({});
            throw request;
        }
        else if (!userProfile) {
            request.error = unauthenticatedError({});
            throw request;
        }
        else if (!bypass &&
            !userProfile.isVideoAlreadyPlayed(program === null || program === void 0 ? void 0 : program.mpxGuid) &&
            userProfile.episodeCount > 0) {
            RouterUtil.navigateToRoute(ROUTE.credits, {
                entity: ROUTE_ENTITY.videoId,
                value: program === null || program === void 0 ? void 0 : program.mpxGuid,
            });
            request.authType = StreamAuthorizationType.SHOW_CREDITS;
            return request;
        }
        else if (userProfile.isVideoAlreadyPlayed(program === null || program === void 0 ? void 0 : program.mpxGuid) || bypass) {
            request.authType = StreamAuthorizationType.CREDIT;
            return request;
        }
        else {
            request.error = noCreditsError(program === null || program === void 0 ? void 0 : program.mpxGuid);
            throw request;
        }
    }
    return request;
};
