import { Language } from '@lightningjs/sdk';
import { ROUTE } from '../constants';
import { useRequest } from '../lib/useRequest';
import { globalNavigationRequest } from '../api/requests';
import Variables from '../graphql/Variables';
class GlobalNavigation {
    constructor() {
        this._initiallySelected = 0;
        this._setDefaultNavItems = () => {
            this.items = [
                {
                    label: Language.translate('home'),
                    route: ROUTE.home,
                    ariaLabel: `${Language.translate('home')}, Page`,
                },
                {
                    label: Language.translate('shows_movies'),
                    route: ROUTE.shows,
                    ariaLabel: `${Language.translate('shows_movies')}, Page`,
                },
                {
                    label: Language.translate('sports'),
                    route: ROUTE.sports,
                    ariaLabel: `${Language.translate('sports')}, Page`,
                },
                {
                    label: Language.translate('live'),
                    route: ROUTE.live,
                    ariaLabel: `${Language.translate('live')}, Page`,
                },
                {
                    label: Language.translate('networks'),
                    route: ROUTE.networks,
                    ariaLabel: `${Language.translate('networks')}, Page`,
                },
                {
                    icon: 'search.png',
                    active: 'search-active.png',
                    route: ROUTE.search,
                    ariaLabel: `${Language.translate('search')}, Page`,
                },
            ];
        };
    }
    consumer(consumer) {
        this._consumer = consumer;
    }
    get items() {
        return this._items;
    }
    set items(v) {
        this._items = v;
        this._consumer.items = this._items;
    }
    set initiallySelected(v) {
        this._initiallySelected = v;
        this._consumer.itemsBar.initiallySelected = v;
    }
    async load() {
        const fetchNavigationData = async () => {
            const data = await useRequest({
                query: globalNavigationRequest,
                variables: Variables.getGlobalNavigationVariables(),
                reject: [(data) => !data || !data.items.length],
            }).fetch();
            const index = data === null || data === void 0 ? void 0 : data.initiallySelected;
            const shouldUpdateIndex = typeof index === 'number' && index !== this._initiallySelected;
            if (shouldUpdateIndex) {
                this.initiallySelected = index;
            }
            this._bffNavigationAdapter(data);
        };
        try {
            await fetchNavigationData();
        }
        catch (error) {
            try {
                // Retry once on failure
                await fetchNavigationData();
            }
            catch (retryError) {
                this._setDefaultNavItems();
            }
        }
    }
    _bffNavigationAdapter(data) {
        var _a;
        if (!((_a = data === null || data === void 0 ? void 0 : data.items) === null || _a === void 0 ? void 0 : _a.length))
            return;
        this.items = data.items.reduce((acc, response) => {
            if (!(response === null || response === void 0 ? void 0 : response.data))
                return acc;
            const { title, defaultLogo, focusedLogo, selectedLogo, destination, ariaLabel } = response.data;
            if ((destination === null || destination === void 0 ? void 0 : destination.toLowerCase()) === ROUTE.olympics)
                return acc;
            acc.push({
                icon: defaultLogo,
                active: selectedLogo,
                focused: focusedLogo,
                route: destination,
                label: title,
                ariaLabel,
            });
            return acc;
        }, []);
    }
}
const GlobalNavigationSingleton = new GlobalNavigation();
export default GlobalNavigationSingleton;
