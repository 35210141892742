import { SecondaryControlButton } from './SecondaryControlButton';
import { COLORS, FONT_FACE } from '../../../../../../constants';
import { Lightning } from '@lightningjs/sdk';
export class SecondaryControlDynamicButton extends SecondaryControlButton {
    constructor() {
        super(...arguments);
        this._isExpanded = false;
        this._isFocused = false;
    }
    static _template() {
        return {
            Shader: {
                rect: true,
            },
            Label: {
                mountY: 0.5,
                text: {
                    fontSize: 28,
                    text: '',
                    textColor: COLORS.white,
                    fontFace: FONT_FACE.regular,
                },
            },
            Icon: { mountY: 0.5, x: 0, y: 0 },
        };
    }
    set label(_label) {
        this._label = _label;
    }
    get label() {
        return this._label;
    }
    expand() {
        this._isExpanded = true;
        this.tag('Label').patch({
            text: { text: this.label },
        });
        this.stage.update();
        this._redraw();
    }
    collapse() {
        this._isExpanded = false;
        this.tag('Label').patch({
            text: { text: '' },
        });
        this.stage.update();
        this._redraw();
    }
    _redraw() {
        const xPadding = 20;
        const gap = 20;
        const iconWidth = this.tag('Icon').renderWidth;
        const labelWidth = this._isExpanded ? this.tag('Label').renderWidth : 0;
        const height = this.tag('Label').renderHeight
            ? this.tag('Label').renderHeight + gap
            : this.tag('Icon').renderHeight + xPadding + gap;
        const middleY = height / 2;
        const totalWidth = iconWidth + labelWidth + xPadding + gap;
        this.patch({
            w: totalWidth,
            h: height,
            Shader: {
                w: totalWidth,
                h: height,
                shader: {
                    type: Lightning.shaders.RoundedRectangle,
                    radius: middleY,
                    fillColor: this._isFocused ? COLORS.white : this._bgColor,
                },
            },
            Label: {
                x: iconWidth + gap,
                y: middleY + 2,
                color: this._isFocused ? COLORS.dark : COLORS.white,
            },
            Icon: {
                x: gap,
                y: middleY + 1,
                color: this._isFocused ? COLORS.dark : COLORS.white,
            },
        });
    }
    _focus() {
        this._isFocused = true;
        this.tag('Label').patch({
            text: { text: this.label },
        });
        this.stage.update();
    }
    _unfocus() {
        this._isFocused = false;
        this.stage.update();
        this._redraw();
    }
}
