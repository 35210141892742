import { Log, Router, Storage } from '@lightningjs/sdk';
import { Accessibility, Advertising, Device, Discovery, Lifecycle, Parameters, } from '@firebolt-js/sdk';
import { uniqueId } from 'lodash';
import { StreamingProtocol } from '@sky-uk-ott/core-video-sdk-js';
import { getDeviceId } from '../../DeviceIdUtils';
import BasePlatform, { PlatformSubscriptionType, SubscriptionWrapper, TV_PLATFORM_TAG, } from '../base';
import { DebugControllerSingleton } from '../../../util/debug/DebugController';
import { APP_IDENTIFIER, ErrorType, LEMONADE_PLATFORM } from '../types';
import { STORAGE_KEYS } from '../../../constants';
import { isXclass } from '../../../helpers';
// Event formatters
import { comcastFormatCC } from './comcastCC';
import { comcastFormatNavigation } from './comcastNavigation';
import { SupportedPlatforms } from '../../../graphql/generated/types';
import { Metrics } from '@firebolt-js/sdk';
class ComcastSubscriptionWrapper extends SubscriptionWrapper {
    constructor(generatedId, module, id) {
        super();
        this._generatedId = generatedId;
        this.module = module;
        this.id = id;
        this.stale = false;
    }
    unsubscribe() {
        var _a, _b;
        if (!this.module) {
            this.stale = true;
        }
        if (!this.stale && ((_a = this.module) === null || _a === void 0 ? void 0 : _a.clear)) {
            this.stale = true;
            return (_b = this.module) === null || _b === void 0 ? void 0 : _b.clear(this.id);
        }
    }
}
export default class ComcastPlatform extends BasePlatform {
    constructor() {
        super(...arguments);
        this._loadingScreenDismissed = false;
        this._platformName = 'platco';
        this._lemonadePlatform = LEMONADE_PLATFORM.XCLASS;
        this._bffPlatform = SupportedPlatforms.Platco;
        this._streamingProtocol = StreamingProtocol.HLS;
        this._appIdentifier = APP_IDENTIFIER.XCLASS;
        this._subscriptions = [];
        this._deviceSdkConfig = { xfinity: { shouldUseFireboltApi: true } };
        this._devicePartnerId = '';
        this.subscribe = (evt, callback) => {
            // Filter stale events
            this._subscriptions = this._subscriptions.filter(({ stale }) => !stale);
            // Generate an unique ID which we can use to filter this subscription
            const generatedId = uniqueId();
            // Call Firebolt which will update subscription wrapper when it's ready
            this._handleAsyncSubscription(generatedId, evt, callback);
            // Meanwhile return the subscription wrapper
            const subscription = new ComcastSubscriptionWrapper(generatedId, undefined, undefined);
            this._subscriptions.push(subscription);
            return subscription;
        };
    }
    get capabilities() {
        return {
            externalAppLinking: false,
            concurrentStreams: !isXclass(),
        };
    }
    get devicePartnerId() {
        return this._devicePartnerId || 'comcast';
    }
    async init() {
        try {
            await this.handleParametersInit();
            await this.generateDeviceId();
            this._deviceType = await Device.type().then((device) => device === 'smarttv' ? 'Xumo TV' : device);
            this._devicePartnerId = await Device.distributor();
            const advertising = await Advertising.advertisingId();
            if ('ifa' in advertising)
                this._advertiserId = advertising.ifa;
            if ('ifa_type' in advertising)
                this._deviceAdvertisingIdType = advertising.ifa_type;
            Log.debug(`Advertiser ID: ${this._advertiserId}, Device Advertising Type: ${this._deviceAdvertisingIdType}`);
            await this._syncDeviceUserOptOut();
        }
        catch (e) {
            this.reportError({
                type: ErrorType.OTHER,
                code: TV_PLATFORM_TAG,
                description: 'Something went wrong while injecting platform script',
                payload: e,
            });
        }
    }
    async dismissLoadingScreen() {
        if (this._loadingScreenDismissed)
            return Promise.resolve();
        await Lifecycle.ready();
        this._loadingScreenDismissed = true;
    }
    async generateDeviceId() {
        try {
            const deviceId = getDeviceId();
            this.deviceId = deviceId !== null && deviceId !== void 0 ? deviceId : (await Device.id());
        }
        catch (e) {
            await super.generateDeviceId();
        }
    }
    /**
     * Sync Device User Opt Out settings
     * Store the last known value and only override app's tracking
     * setting if we detect there's been a change
     */
    _syncDeviceUserOptOut() {
        if (!isXclass()) {
            return;
        }
        const LAST_KNOWN_DEVICE_UOO_SETTING = 'lastKnownDeviceUooSetting';
        const lastValue = Number(Storage.get(LAST_KNOWN_DEVICE_UOO_SETTING));
        const value = DebugControllerSingleton._getAdTrackingPreferenceFromUrl();
        Log.info(`Current device ad tracking preference: ${value}. Previous known value: ${lastValue}`);
        if (value !== null && lastValue !== value) {
            Storage.set(LAST_KNOWN_DEVICE_UOO_SETTING, value);
            Storage.set(STORAGE_KEYS.USER_OPT_OUT, value);
        }
    }
    getAdvertiserDeviceType() {
        return 'platco';
    }
    /**
     * As Xclass platform doesn't use deeplinking we do nothing here
     */
    handleDeepLink() { }
    async getModelNumber() {
        try {
            return await Device.model();
        }
        catch (e) {
            return await super.getModelNumber();
        }
    }
    getStageSettings() {
        return Object.assign(Object.assign({}, super.getStageSettings()), { forceTxCanvasSource: true });
    }
    async _handleAsyncSubscription(generatedId, evt, callback) {
        try {
            let subscriptionId, module;
            switch (evt) {
                case PlatformSubscriptionType.VOICE:
                    module = Accessibility;
                    subscriptionId = await Accessibility.listen('voiceGuidanceSettingsChanged', callback);
                    if (isXclass()) {
                        callback((await Accessibility.voiceGuidanceSettings()).enabled);
                    }
                    break;
                case PlatformSubscriptionType.VOICE_CONTROL:
                    module = Discovery;
                    subscriptionId = await Discovery.listen('navigateTo', (e) => callback(comcastFormatNavigation(e)));
                    break;
                case PlatformSubscriptionType.CC:
                    module = Accessibility;
                    await Accessibility.closedCaptionsSettings().then((data) => {
                        callback(data.enabled, comcastFormatCC(data.styles));
                    });
                    subscriptionId = await Accessibility.listen('closedCaptionsSettingsChanged', ({ enabled, styles }) => callback(enabled, comcastFormatCC(styles)));
                    break;
                case PlatformSubscriptionType.BACKGROUND: {
                    module = Lifecycle;
                    // There's a Firebolt event called BACKGROUND, but per Comcast documentation we MUST use INACTIVE.
                    // BACKGROUND isn't triggered in all cases (i.e.: player background, screen saver being activated...)
                    subscriptionId = await Lifecycle.listen(Lifecycle.LifecycleState.INACTIVE, callback);
                    break;
                }
                case PlatformSubscriptionType.FOREGROUND:
                    module = Lifecycle;
                    subscriptionId = await Lifecycle.listen(Lifecycle.LifecycleState.FOREGROUND, callback);
                    break;
                default:
                    break;
            }
            if (subscriptionId !== undefined && module) {
                const subscription = this._subscriptions.find(({ _generatedId }) => _generatedId === generatedId);
                // We found the subscription wrapper, so update it with the final values
                if (subscription) {
                    subscription.module = module;
                    subscription.id = subscriptionId;
                }
                else {
                    // The subscription wrapper was made at some time
                    // but we unsubscribed before the async call ended
                    // so we must clear the Firebolt event listener
                    // and act nothing's happened
                    module.clear(subscriptionId);
                }
            }
        }
        catch (e) {
            // We won't TVPlatform.reportError this one since it's an internal subscription
            Log.error(TV_PLATFORM_TAG, e);
        }
    }
    async handleParametersInit() {
        const initialParams = await Parameters.initialization();
        Log.info(`${TV_PLATFORM_TAG}-initialization`, initialParams);
    }
    exit() {
        // super.exit()
        // Don't call super, because Firebolt doesn't actually close the app when calling Lifecycle.close
        // We still need the subscriptions for when we return to active state
        // Ref: https://docs.developer.comcast.com/docs/lifecycle-management [Closing your App section]
        Lifecycle.close(Lifecycle.CloseReason.USER_EXIT);
        Lifecycle.finished();
    }
    exitToPeacock() {
        super.exitToPeacock();
    }
    get deviceInfo() {
        return {
            primaryHardwareType: 'TV',
            model: 'None',
            version: 'None',
            manufacturer: 'Hisense',
            vendor: 'Comcast',
            osName: 'Linux',
            osFamily: 'Linux',
            osVendor: 'None',
            osVersion: 'None',
            browserName: 'Symbian Browser',
            browserVendor: 'Netscape',
            browserVersion: 'None',
            userAgent: window.navigator.userAgent,
            displayWidth: window.innerWidth,
            displayHeight: window.innerHeight,
            displayPpi: 0,
            diagonalScreeenSize: 0,
            connectionIp: 'None',
            connectionPort: 0,
            connectionType: 'None',
            connectionSecure: false,
            applicationId: 'None',
        };
    }
    reportError(error) {
        super.reportError(error);
        Metrics.error(error.type, error.code || '', error.description, true, error.payload
            ? typeof error.payload === 'object'
                ? error.payload
                : { detail: error.payload }
            : undefined).catch(() => { });
    }
    historyBack() {
        Router.back();
    }
}
