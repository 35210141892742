export const isNumberLike = (input) => {
    // Handle arrays and objects
    if (typeof input === 'object') {
        return false;
    }
    // Handle empty strings and whitespace
    if (typeof input === 'string' && input.trim() === '') {
        return false;
    }
    // Then check if it's a valid number or can be converted to one
    const num = Number(input);
    return !Number.isNaN(num) && input !== null && input !== undefined;
};
export const looseStringCompare = (...arr) => {
    for (let i = 0, previous; i < arr.length; i++) {
        const current = arr[i];
        const equal = (previous === null || previous === void 0 ? void 0 : previous.toLowerCase()) === (current === null || current === void 0 ? void 0 : current.toLowerCase());
        if (i > 0 && !equal)
            return false;
        previous = current;
    }
    return true;
};
