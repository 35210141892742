import Announce from './Announce';
export default class BaseAnnounce extends Announce {
    async speak() {
        return new Promise((resolve, reject) => {
            this._utterance = new SpeechSynthesisUtterance(this._phrase);
            this._utterance.onend = () => resolve();
            this._utterance.onerror = (e) => {
                // Unknown, cancel or interrupt error (ignore)
                if (e.error && !['canceled', 'interrupted'].includes(e.error))
                    reject(e);
            };
            window.speechSynthesis.speak(this._utterance);
            window.speechSynthesis.resume();
            const suppressPromise = () => {
                if (!window.speechSynthesis.speaking) {
                    resolve();
                    return;
                }
                window.setTimeout(suppressPromise, 200);
            };
            suppressPromise();
        });
    }
}
