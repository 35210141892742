import { Log } from '@lightningjs/sdk';
import { get } from 'lodash';
import { CoreVideoSdk, SessionState, VideoPlatform, } from '@sky-uk-ott/core-video-sdk-js';
import { loadDeviceFactory } from '@sky-uk-ott/client-lib-js-device';
import { PlayerInterface, PlayerMode, PlayerState } from '../core/PlayerInterface';
import { getCoreStreamUrl, getCoreVideoSdkConfig, getPlayoutData, getSessionConfig, } from './getCoreVideoSdkConfig';
import AppConfigFactorySingleton from '../../config/AppConfigFactory';
import { PlayerStatus } from '../model/PlayerStatus';
import { ErrorEvent, FatalErrorEvent, LoadSourceEvent, PlayerStatusEvent, SubtitleEvent, } from '../model/event';
import { PlayerError } from '../model/PlayerError';
import { CCTypes, ClosedCaptionsUtils } from '../../lib/ClosedCaptions/ClosedCaptionsUtils';
import { AvailableEmitters, PlayerEventEmitterRegistry, PlayerEventSetup, } from '../model/emitter/PlayerEventEmitterRegistry';
import { getMpid, getProduct, isNBCProfileLinked, isProduction, isXbox, isXclass, } from '../../helpers';
import PlayerStoreSingleton from '../../store/PlayerStore/PlayerStore';
import { AAMPCCDelegate } from './delegates/CC/AAMPCCDelegate';
import AuthenticationSingleton from '../../authentication/Authentication';
import { PLAYER_SIZE, SCREEN_SIZE } from '../../constants';
import TVPlatform from '../../lib/tv-platform';
import { AnnouncerEvent } from '../../lib/tts/Announcer';
import { SubscriptionBuilder, SubscriptionSources } from '../../util/SubscriptionBuilder';
import { ErrorType } from '../../lib/tv-platform/types';
const CORE_VIDEO_TAG = 'Core Video Player';
const isSizeArray = (input) => Array.isArray(input) &&
    input.length === 4 &&
    (input === null || input === void 0 ? void 0 : input.every((value) => typeof value === 'number'));
export class CoreVideoPlayer extends PlayerInterface {
    constructor(playerMode, options) {
        super(playerMode, options);
        // Core Video Controllers
        this._coreVideoController = null;
        this._sessionController = null;
        // Assets
        this._subtitleCues = [];
        this._subtitleTracks = [];
        this.loadDevice = async () => {
            const deviceFactory = await loadDeviceFactory(TVPlatform.deviceSdkConfig);
            return await deviceFactory.initialise();
        };
        this.onCoreSessionCreated = (controller) => {
            var _a;
            this._sessionController = controller;
            if (!this._playerEventEmitterRegistry)
                this._playerEventEmitterRegistry = new PlayerEventEmitterRegistry(this._playerMode === PlayerMode.FULL ? PlayerEventSetup.ALL : PlayerEventSetup.MINIMAL);
            (_a = this._playerEventEmitterRegistry) === null || _a === void 0 ? void 0 : _a.attach(controller, this.normalizedPlayerEvents);
        };
        //#region May need to be removed and pass in the html element
        this._setupVideoTag = () => {
            var _a, _b, _c, _d, _e;
            const element = this._findVideoElement();
            if (element)
                return element;
            const left = ((_a = this._options) === null || _a === void 0 ? void 0 : _a.left) || 0;
            const top = ((_b = this._options) === null || _b === void 0 ? void 0 : _b.top) || 0;
            const width = ((_c = this._options) === null || _c === void 0 ? void 0 : _c.width) ||
                ((isProduction() || isXbox()) && window.innerWidth ? window.innerWidth : SCREEN_SIZE.width);
            const height = ((_d = this._options) === null || _d === void 0 ? void 0 : _d.height) || width / (16 / 9);
            const videoEl = document.createElement('div');
            videoEl.setAttribute('id', this._domId);
            videoEl.style.position = 'absolute';
            videoEl.style.zIndex = String(((_e = this._options) === null || _e === void 0 ? void 0 : _e.zIndex) || 0);
            document.body.appendChild(videoEl);
            this._playerDomEl = videoEl;
            this.setVideoSize(left, top, width, height);
            this.setVisibility(false);
            return videoEl;
        };
        this._onError = (error = { fatal: false }) => {
            if (error.fatal) {
                // Attempt to recover from fatal error.
                if (!this.isRecovering) {
                    TVPlatform.reportError({
                        type: ErrorType.MEDIA,
                        code: CORE_VIDEO_TAG,
                        description: 'unknown error, trying to recover media error',
                    });
                    this._onUnableToRecoverMedia(error);
                    return;
                }
            }
        };
        this._onRecoverMediaError = () => {
            // Show buffer screen while attempted to recover.
            if (!this.isBuffering)
                this._state = PlayerState.BUFFERING;
            Log.info(`${CORE_VIDEO_TAG} attempt to recover media`);
            // Allow 10s for media to recover.
            this._mediaRecoveryTimeout = window.setTimeout(() => {
                this._state = PlayerState.ERROR;
            }, get(AppConfigFactorySingleton.config, 'hls_player.recoverErrorTimeout', CoreVideoPlayer.RECOVER_ERROR_TIMEOUT));
        };
        if (isXclass())
            this._ccDelegate = new AAMPCCDelegate(this);
        this._ttsSubscription = new SubscriptionBuilder()
            .with({
            type: SubscriptionSources.TTS_ANNOUNCER,
            events: [AnnouncerEvent.TTS_START],
            handler: this._startTts.bind(this),
        })
            .with({
            type: SubscriptionSources.TTS_ANNOUNCER,
            events: [AnnouncerEvent.TTS_END],
            handler: this._endTts.bind(this),
        })
            .subscribe();
    }
    get id() {
        return 'com.nbc.player.corevideoplayer';
    }
    get framework() {
        return 'Core Video';
    }
    get version() {
        return CoreVideoSdk.version;
    }
    _getPlayoutData(vod) {
        return () => getPlayoutData(vod);
    }
    async loadCoreSdk() {
        var _a;
        Log.info(`${CORE_VIDEO_TAG} attempt to loadSdk`);
        // Load the sdk
        const sdkConfig = getCoreVideoSdkConfig();
        Log.info(`${CORE_VIDEO_TAG} sdk config:`, sdkConfig);
        Log.info(`${CORE_VIDEO_TAG} sdk version:`, CoreVideoSdk.version);
        try {
            const device = await this.loadDevice();
            Log.info('>>> device', { device });
            await CoreVideoSdk.initialise(sdkConfig, device, {
                type: VideoPlatform.OVP,
                getVodPlayoutData: this._getPlayoutData(true),
                getPreviewPlayoutData: this._getPlayoutData(true),
                getLivePlayoutData: this._getPlayoutData(false),
                getEventPlayoutData: this._getPlayoutData(false),
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                sendHeartbeat: async () => { },
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                stopHeartbeat: async () => { },
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                updateBookmark: async () => { },
                validateLicenseResponse: () => true,
                decorateLicenseRequest: (licenseRequest) => licenseRequest,
            });
            Log.info(`${CORE_VIDEO_TAG} loadSdk successful`);
        }
        catch (err) {
            switch (err.message) {
                case 'Core SDK has already been initialised':
                    Log.info(`${CORE_VIDEO_TAG} core video sdk already loaded`);
                    return;
                default:
                    TVPlatform.reportError({
                        type: ErrorType.MEDIA,
                        code: CORE_VIDEO_TAG,
                        description: 'loadSdk catastrophically failed',
                        payload: err,
                    });
                    (_a = this._normalizedPlayerEvents) === null || _a === void 0 ? void 0 : _a.publish(new ErrorEvent(err));
                    return;
            }
        }
    }
    _setCoreConfig() {
        this._coreConfig = {
            enableWorker: false,
        };
    }
    isPlayingAd() {
        var _a, _b;
        return (((_b = (_a = this._playerEventEmitterRegistry) === null || _a === void 0 ? void 0 : _a.getEmitter(AvailableEmitters.CORE_AD_REPORTER)) === null || _b === void 0 ? void 0 : _b.isAdPlaying) || false);
    }
    _onEventReceived(event) {
        super._onEventReceived(event);
        if (event instanceof PlayerStatusEvent) {
            switch (event.status) {
                case PlayerStatus.PLAYING:
                    // We have to enable subtitles each time both session controller
                    // and subtitle track are ready
                    this._updateSubtitles();
                    break;
                default:
                    break;
            }
        }
        else if (event instanceof SubtitleEvent) {
            const { cues, tracks } = event.subtitleData;
            if (cues) {
                this._subtitleCues = cues;
            }
            else if (tracks) {
                this._subtitleTracks = tracks;
            }
        }
    }
    _updateSubtitles() {
        this.enableSubtitles(ClosedCaptionsUtils.getCCType());
    }
    async _initializeVideo() {
        if (typeof window !== 'undefined')
            await this.loadCoreSdk();
        // Creates video player element to hook onto via LightningSDK.
        this._setupVideoTag();
        if (this._playerDomEl)
            this.setVisibility(true);
    }
    async _clearSession() {
        var _a, _b, _c, _d;
        super._clearSession();
        (_c = (_b = (_a = this._playerDomEl) === null || _a === void 0 ? void 0 : _a.parentNode) === null || _b === void 0 ? void 0 : _b.removeChild) === null || _c === void 0 ? void 0 : _c.call(_b, this._playerDomEl);
        this._playerDomEl = null;
        (_d = this._coreVideoController) === null || _d === void 0 ? void 0 : _d.destroy();
        this._coreVideoController = null;
        if (this._sessionController)
            await this._sessionController.stop();
        this._sessionController = null;
        this._playerStatus = PlayerStatus.UNKNOWN;
    }
    async load(override) {
        var _a, _b, _c;
        this._override = override;
        try {
            await this._clearSession();
            const { stream, program, lemonade } = this._override || PlayerStoreSingleton;
            // Check if we dont get the response from lemonade
            const missingLemonade = !(lemonade === null || lemonade === void 0 ? void 0 : lemonade.type) && (!(lemonade === null || lemonade === void 0 ? void 0 : lemonade.playbackUrl) || !(lemonade === null || lemonade === void 0 ? void 0 : lemonade.playbackUrls));
            if (missingLemonade || !stream || !program) {
                this._onUnableToRecoverMedia(lemonade === null || lemonade === void 0 ? void 0 : lemonade.message);
                return;
            }
            await this._initializeVideo();
            // This is important for recovering from media failure since the current time gets wiped.
            // Without this we could get a infinte loop when buffering
            this._lastPosition = 0;
            this._startTime =
                (_a = ('startTime' in stream && typeof stream.startTime === 'number'
                    ? stream.startTime
                    : 'startTime' in program && typeof program.startTime === 'number'
                        ? program.startTime
                        : undefined)) !== null && _a !== void 0 ? _a : -1;
            await this.createPlaybackSession();
            (_b = this._normalizedPlayerEvents) === null || _b === void 0 ? void 0 : _b.publish(new LoadSourceEvent(getCoreStreamUrl(lemonade), program));
        }
        catch (error) {
            TVPlatform.reportError({
                type: ErrorType.MEDIA,
                code: CORE_VIDEO_TAG,
                description: 'load error event',
                payload: error,
            });
            // Case when stream is undefined
            const playerError = new PlayerError(true, null, null);
            (_c = this._normalizedPlayerEvents) === null || _c === void 0 ? void 0 : _c.publish(new ErrorEvent(playerError));
        }
    }
    async createPlaybackSession() {
        const mParticleId = getMpid();
        const mvpd = AuthenticationSingleton.getMvpdData();
        const { mvpdProviderData } = mvpd !== null && mvpd !== void 0 ? mvpd : {};
        const { stream, program, lemonade, geo } = this._override || PlayerStoreSingleton;
        if (!lemonade || !stream || !program)
            return;
        const sessionItem = await getSessionConfig({
            lemonade,
            program,
            mvpdHash: mvpdProviderData === null || mvpdProviderData === void 0 ? void 0 : mvpdProviderData.advertisingKey,
            mParticleId,
            mvpd,
            product: getProduct(),
            isNBCProfileLinked: isNBCProfileLinked(),
            stream,
            raw: !!this._override,
            geo,
        });
        Log.info(`${CORE_VIDEO_TAG} session item config:`, sessionItem);
        this._coreVideoController = CoreVideoSdk.getPlayerController({
            videoHtmlContainerInfo: {
                videoHTMLContainer: this._setupVideoTag(),
            },
            disablePlayoutRect: true,
        });
        this._coreVideoController.onSessionCreated(this.onCoreSessionCreated);
        this._coreVideoController.createSession(sessionItem);
    }
    setCCStyle(options) {
        var _a;
        (_a = this._ccDelegate) === null || _a === void 0 ? void 0 : _a.setCCStyle(options);
    }
    play() {
        var _a, _b;
        (_b = (_a = this._sessionController) === null || _a === void 0 ? void 0 : _a.play) === null || _b === void 0 ? void 0 : _b.call(_a);
    }
    pause() {
        var _a, _b;
        (_b = (_a = this._sessionController) === null || _a === void 0 ? void 0 : _a.pause) === null || _b === void 0 ? void 0 : _b.call(_a);
    }
    seek(positionInMilliseconds) {
        var _a, _b;
        if (!this._sessionController)
            return;
        const position = this.getSafeSeekPosition(positionInMilliseconds);
        (_b = (_a = this._sessionController) === null || _a === void 0 ? void 0 : _a.seek) === null || _b === void 0 ? void 0 : _b.call(_a, positionInMilliseconds);
        Log.info(`${CORE_VIDEO_TAG} seeking to position ${position}`);
    }
    seekToLiveEdge() {
        var _a;
        if (!this._sessionController)
            return;
        (_a = this._sessionController) === null || _a === void 0 ? void 0 : _a.seekToLiveEdge();
        Log.info(`${CORE_VIDEO_TAG} seeking to live edge`);
    }
    clearPreviousSession() {
        this._clearSession();
    }
    close() {
        var _a;
        (_a = this._ttsSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this._ttsSubscription = undefined;
        this._detach();
    }
    isPlaying() {
        var _a, _b;
        const sessionState = (_b = (_a = this._sessionController) === null || _a === void 0 ? void 0 : _a.getCurrentSessionState) === null || _b === void 0 ? void 0 : _b.call(_a);
        Log.info(`${CORE_VIDEO_TAG} isPlaying - sessionState: ${sessionState}`);
        return sessionState === SessionState.Playing;
    }
    enableSubtitles(language) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (language === CCTypes.off) {
            (_b = (_a = this._sessionController) === null || _a === void 0 ? void 0 : _a.disableSubtitles) === null || _b === void 0 ? void 0 : _b.call(_a);
            return;
        }
        let subtitleTrack = (_d = (_c = this._subtitleTracks) === null || _c === void 0 ? void 0 : _c.filter) === null || _d === void 0 ? void 0 : _d.call(_c, (track) => track.language === language);
        // if we can't find a track, check for the fallback language
        if ((subtitleTrack === null || subtitleTrack === void 0 ? void 0 : subtitleTrack.length) === 0) {
            subtitleTrack = (_f = (_e = this._subtitleTracks) === null || _e === void 0 ? void 0 : _e.filter) === null || _f === void 0 ? void 0 : _f.call(_e, (track) => track.language === ClosedCaptionsUtils.getFallbackCCType(language));
        }
        if (subtitleTrack) {
            const { id } = subtitleTrack[0] || {};
            if (id)
                (_h = (_g = this._sessionController) === null || _g === void 0 ? void 0 : _g.enableSubtitles) === null || _h === void 0 ? void 0 : _h.call(_g, id);
        }
    }
    _startTts() {
        this.setVolume(0.2);
    }
    _endTts() {
        this.setVolume(1);
    }
    setVolume(level) {
        var _a, _b, _c, _d;
        if (isXclass()) {
            // The private property is used here because we don't have any other public property to call the player engine element
            // @ts-expect-error TS2341: Property 'engine' is private and only accessible within class 'PlayerController'.
            // rdkaamp uses volume levels of 0-100 instead of 0-1, so we need to adjust volume level when XClass
            (_c = (_b = (_a = this._coreVideoController) === null || _a === void 0 ? void 0 : _a.engine) === null || _b === void 0 ? void 0 : _b.playerItem) === null || _c === void 0 ? void 0 : _c.setVolume(level * 100);
        }
        else {
            (_d = this._sessionController) === null || _d === void 0 ? void 0 : _d.setVolume(level);
        }
    }
    get liveLatency() {
        return 0; // Math.round(this._hls?.latency ?? 0)
    }
    get bandwidthEstimate() {
        // in bps
        return 0;
    }
    get videoSize() {
        return [0, 0];
    }
    set closedCaptionsEnabled(enabled) {
        // FIXME: implement this or remove it altogether
    }
    get closedCaptionLanguages() {
        // TODO: Implement closed captions functionality
        throw new Error('Method not implemented.');
    }
    set closedCaptions(code) {
        // TODO: Implement closed captions functionality
        throw new Error('Method not implemented.');
    }
    get audioTracks() {
        // TODO: Implement audioTrack functionality
        throw new Error('Method not implemented.');
    }
    set audioTrack(code) {
        // TODO: Implement audioTrack functionality
        throw new Error('Method not implemented.');
    }
    get audioTrack() {
        // TODO: Implement audioTrack functionality
        throw new Error('Method not implemented.');
    }
    get startPosition() {
        return Math.round(this._startTime);
    }
    get currentPosition() {
        return 0;
    }
    get lastPosition() {
        return this._lastPosition;
    }
    get seekableRange() {
        return 0;
    }
    get duration() {
        return 0;
    }
    get view() {
        const videoEls = document.getElementsByTagName('video');
        if (videoEls && videoEls.length) {
            return videoEls[0];
        }
        else {
            throw new Error('Method not implemented.');
        }
    }
    get isBuffering() {
        var _a;
        return [SessionState.Rebuffering, SessionState.Seeking].includes((_a = this._sessionController) === null || _a === void 0 ? void 0 : _a.getCurrentSessionState());
    }
    _findVideoElement() {
        return this._playerDomEl || document.getElementById(this._domId);
    }
    setVideoSize(left, top, width, height) {
        var _a;
        const videoEl = this._findVideoElement();
        if (!videoEl)
            return;
        const playOutRect = {
            width: TVPlatform.scaleVideoProperty(width, PLAYER_SIZE.FULL),
            height: TVPlatform.scaleVideoProperty(height, PLAYER_SIZE.FULL),
            top: TVPlatform.scaleVideoProperty(top, PLAYER_SIZE.FULL),
            left: TVPlatform.scaleVideoProperty(left, PLAYER_SIZE.FULL),
        };
        videoEl.setAttribute('width', `${playOutRect.width}px`);
        videoEl.setAttribute('height', `${playOutRect.height}px`);
        Object.entries(playOutRect).forEach(([k, v]) => (videoEl.style[k] = `${v}px`));
        // TODO: Refactor this as a separate delegate
        if (isXclass()) {
            (_a = this._coreVideoController) === null || _a === void 0 ? void 0 : _a.setPlayoutRect({
                width: playOutRect.width,
                height: playOutRect.height,
                y: playOutRect.top,
                x: playOutRect.left,
            });
        }
    }
    forceVideoSize() {
        var _a, _b, _c, _d;
        const size = [
            (_a = this._options) === null || _a === void 0 ? void 0 : _a.left,
            (_b = this._options) === null || _b === void 0 ? void 0 : _b.top,
            (_c = this._options) === null || _c === void 0 ? void 0 : _c.width,
            (_d = this._options) === null || _d === void 0 ? void 0 : _d.height,
        ];
        if (isSizeArray(size)) {
            this.setVideoSize(...size);
        }
    }
    setVisibility(visible) {
        const videoEl = this._findVideoElement();
        if (!videoEl)
            return;
        videoEl.style.display = visible ? 'block' : 'none';
        videoEl.style.visibility = visible ? 'visible' : 'hidden';
    }
    setMute(muted) {
        var _a, _b, _c, _d;
        if (isXclass()) {
            // The private property is used here because we don't have any other public property to call the player engine element
            // @ts-expect-error TS2341: Property 'engine' is private and only accessible within class 'PlayerController'.
            (_c = (_b = (_a = this._coreVideoController) === null || _a === void 0 ? void 0 : _a.engine) === null || _b === void 0 ? void 0 : _b.playerItem) === null || _c === void 0 ? void 0 : _c.setMute(muted);
        }
        else {
            (_d = this._sessionController) === null || _d === void 0 ? void 0 : _d.setMute(muted);
        }
    }
    _onUnableToRecoverMedia(error) {
        var _a;
        TVPlatform.reportError({
            type: ErrorType.MEDIA,
            code: CORE_VIDEO_TAG,
            description: 'unable to recover fatal error, end session',
        });
        (_a = this._normalizedPlayerEvents) === null || _a === void 0 ? void 0 : _a.publish(new FatalErrorEvent(error));
        this.close();
    }
    setAudioTrack(trackId) {
        var _a;
        (_a = this._sessionController) === null || _a === void 0 ? void 0 : _a.setAudioTrack(trackId);
    }
}
CoreVideoPlayer.RECOVER_ERROR_TIMEOUT = 10000;
