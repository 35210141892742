import RouterUtil from '../../../../util/RouterUtil';
import pageAttributes from './pageAttributes';
import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../../../constants';
import { getBrandName } from '../../../../helpers';
const pageAttributeFallback = {
    name: MPARTICLE_DEFAULT_ATTR_VALUE,
    type: MPARTICLE_DEFAULT_ATTR_VALUE,
};
const getPageAttributes = (path = null, current = true) => {
    if (!path)
        path = current ? RouterUtil.current() : RouterUtil.getPrevRoute();
    const page = typeof path === 'string' ? pageAttributes[path] : undefined;
    const attributes = (page === null || page === void 0 ? void 0 : page[getBrandName()]) || (page === null || page === void 0 ? void 0 : page.default);
    if (!attributes)
        return pageAttributeFallback;
    if (typeof (attributes === null || attributes === void 0 ? void 0 : attributes.name) === 'function')
        return Object.assign(Object.assign({}, attributes), { name: attributes.name() });
    return attributes;
};
export const getReferrer = () => {
    return getPageAttributes(null, true).name;
};
export const getCurrentPageType = () => {
    return getPageAttributes().type;
};
export default getPageAttributes;
