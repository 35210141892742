// @ts-expect-error TS(7016): Could not find a declaration file for module 'js-c... Remove this comment to see the full error message
import Cookies from 'js-cookie';
import { Storage } from '@lightningjs/sdk';
import { FavoriteInteractionFactorySingleton } from '../graphql/modifiers/FavoriteInteractionFactory';
import { WatchProgressFactorySingleton } from '../graphql/modifiers/WatchProgressFactory';
import { getUserProfile } from '../api/Identity';
import AppConfigFactory from '../config/AppConfigFactory';
import AuthenticationSingleton from '../authentication/Authentication';
import { STORAGE_KEYS, UNAUNTHENTICATED } from '../constants';
export const checkGetMvpd = () => new Promise((res, rej) => {
    AuthenticationSingleton.checkStatus()
        .then(() => res(getMvpd()))
        .catch((err) => rej(err));
});
export const getMvpdServiceBaseUrl = () => {
    return AppConfigFactory.get().config.mvpdService.baseUrl;
};
export const getMvpdLogo = (mvpd = null) => {
    var _a;
    if (!mvpd)
        return;
    return `${getMvpdServiceBaseUrl()}/${(_a = mvpd === null || mvpd === void 0 ? void 0 : mvpd.mvpdProviderData) === null || _a === void 0 ? void 0 : _a.apploggedInImage}`;
};
export const getMvpdLogoSrc = (src) => {
    if (!src)
        return;
    return `${getMvpdServiceBaseUrl()}/${src}`;
};
export const getProfileValue = () => (getUserProfile() ? 'Free' : 'Unauthenticated');
export const getMvpd = () => AuthenticationSingleton.isAuthenticated()
    ? AuthenticationSingleton.getMvpdData()
    : UNAUNTHENTICATED;
export const getMvpdValue = () => { var _a; return ((_a = getMvpd()) === null || _a === void 0 ? void 0 : _a.mvpdDisplayName) || 'Unauthenticated'; };
export const getCookieNamesByPrefix = (prefix) => {
    const cookies = document.cookie.split(';');
    return cookies
        .filter((ck) => ck && ck.indexOf(prefix) > -1)
        .map((ck) => { var _a; return (_a = ck === null || ck === void 0 ? void 0 : ck.split('=')[0]) === null || _a === void 0 ? void 0 : _a.replace(/\s/g, ''); });
};
export const getCookie = (name) => Cookies.get(name);
export const removeCookie = (name, params) => {
    if (params)
        Cookies.remove(name, params);
    else
        Cookies.remove(name);
};
/**
 * Check if user has a profile or mvpd linked
 * hasn't saved a show to favorites or watched content
 * @return {boolean} is user anonymous
 */
export const getIsDayZero = () => {
    const isMVPDLinked = AuthenticationSingleton.isAuthenticated();
    const hasWatchedContent = WatchProgressFactorySingleton.get().hasWatchedContent;
    const hasSavedFavorite = FavoriteInteractionFactorySingleton.get().hasSavedFavorite;
    const wasDayZeroStored = Storage.get(STORAGE_KEYS.IS_NOT_DAY_ZERO);
    return (!(isMVPDLinked || isNBCProfileLinked()) &&
        !hasWatchedContent &&
        !hasSavedFavorite &&
        !wasDayZeroStored);
};
export const isNBCProfileLinked = () => {
    return !!getUserProfile();
};
export const transformResources = (resources) => {
    const resourceObject = {};
    resources.forEach((resource) => {
        resourceObject[resource.id] = resource.authorized;
    });
    return resourceObject;
};
